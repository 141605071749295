<template>
  <div class="resultPage">
    <img src="../../assets/signSuccess.png" />
    <span class="title">报名缴费成功</span>

    <span class="desc">您可以在我的报名记录中查看详情</span>
    <Button class="mar-t-40" color="#2b72ff" block @click="makeOutInvoice">开具发票</Button>
    <Button color="#2b72ff" plain block @click="viewEnrollment" class="mar-t-15">查看报名</Button>
  </div>
</template>

<script>
import { Button } from "vant";
import config from '@/utils/config.js'

export default {
  name: "signSuccess",
  components: {
    Button,
  },
  data(){
    return{
      orderId:'',
      countNum:'',
      money:''
    }
  },
  created(){
    let {orderId,money,countNum}=this.$route.query;
    this.orderId=orderId;
    this.money=money;
    this.countNum=countNum;
   
  },
  mounted(){
   
    let mchData={action:"onIframeReady",displayStyle:"SHOW_CUSTOM_PAGE"};
    let postData=JSON.stringify(mchData)   
     parent.postMessage(postData, 'https://payapp.weixin.qq.com') 
  },
  methods: {
    //开具发票
    makeOutInvoice() {
      this.$router.push(`/invoice?goodsId=0000&orderId=${this.orderId}&countNum=${this.countNum}&money=${this.money}`)
      // let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+'/invoice'};
      // let postData=JSON.stringify(mchData)   
      // parent.postMessage(postData, 'https://payapp.weixin.qq.com') 
    },
    //查看报名
    viewEnrollment() {
      this.$router.push('/myMeetList')
       // let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+'/myMeetList'};
      // let postData=JSON.stringify(mchData)   
      // parent.postMessage(postData, 'https://payapp.weixin.qq.com') 
    },
  },
};
</script>

<style lang="less" scoped>
@import "./common.less";
.resultPage{
  width:600px;
  height:640px;
  margin:0 auto;
}
</style>
